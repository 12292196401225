import "../../../PlayStore.scss";
import AppStatistics from "./AppStatistics/AppStatistics";

function AppInfo() {

  return (
    <>
    <div className="Il7kR">
      <div className="appInfoWrapper appInfoWrapperOverride1 appInfoWrapperOverride2">
        <div className="qxNhq">
          <h1 className="appHeaderTitle appHeaderTitleOverride1 appHeaderTitleOverride2"><span>Slime Simulator
            Games</span></h1>
          <div className="subtitleWrapper">
            <div className="greenTitleText greenTitleTextOverride"><span>Solid
              Apps INC.</span></div>
            <div className="adDisclaimerWrapper">
              <div className=""><span className="adDisclaimer">Contains ads</span><span className="adDisclaimer">In-app purchases</span></div>
            </div>
          </div>
          <AppStatistics />
        </div>
      </div>
      </div>
    </>

  );
}

export default AppInfo;




