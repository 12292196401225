import './App.scss';
import BodySection from './Components/BodySection/BodySection';
import HeadSection from './Components/HeadSection/HeadSection';
function App() {
  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <HeadSection />
    </div>
  );
}

export default App;
