import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import "../../../PlayStore.scss";

function Trailer() {
    const [modalVideoOpen, setModalVideoOpen] = useState(false);

    return (
        <>
            <div className="trailerButtonWrapper">
                <div className="modalVideoWrapper">
                    <React.Fragment>
                        <ModalVideo channel='youtube' isOpen={modalVideoOpen} videoId="he11MxHvjOI" onClose={() => setModalVideoOpen(false)} />
                        <button onClick={() => setModalVideoOpen(true)} className="trailerButton"><i className="google-material-icons">play_arrow</i>Trailer</button>
                    </React.Fragment>
                </div>
            </div>
        </>

    );
}

export default Trailer;






