import "../../PlayStore.scss";
import AppInfo from "./AppInfo/AppInfo";
import InstallAndWishlist from "./InstallAndWishlist/InstallAndWishlist";
import Trailer from "./Trailer/Trailer";


function HeadSection() {

  return (
    <>
        <div className="headerWrapper">
          <div className="innerHeaderWrapper">
            <div className="innerHeaderWrapper2">
              <div className="backgroundVideoWrapper">
                <video className="backgroundVideo" src="https://play-games.googleusercontent.com/vp/mp4/1280x720/he11MxHvjOI.mp4" autoPlay muted loop/>
                <div className="linearGradient" />
              </div>
              <AppInfo/>
              <div className="installRowWrapper">
                {/* <InstallAndWishlist/> */}
                <Trailer/>
              </div>
              <div className="deviceAvailabilityWrapper">
                <div className="deviceAvailability "><i className="google-material-icons deviceAvailableIcon">devices</i><span>This app is available for
                  your device</span></div>
              </div>
            </div>
          </div>
        </div>
      </>

  );
}

export default HeadSection;
