import "../../../../PlayStore.scss";

function AppStatistics() {

    return (
        <>
            <div className="appStatisticsWrapper">
                <div className="appLogoImgWrapper"><img src="https://play-lh.googleusercontent.com/r9Hhjwv5vM7d4zkJ8uIeeKJZA56iPBR9YuOoORnBQ86ylB3ezvx_P8ruleSBsloKQs1r=s48" srcSet="https://play-lh.googleusercontent.com/r9Hhjwv5vM7d4zkJ8uIeeKJZA56iPBR9YuOoORnBQ86ylB3ezvx_P8ruleSBsloKQs1r=s96 2x" style={{"backgroundSize":"contain"}} className="appLogoImg" alt="Icon image" />
                    <div className="appStatisticsElement">
                        <div className="appStatisticsElementWrapper">
                            <div>
                                <div className="starIcon">4.8<i className="google-material-icons font12">star</i></div>
                            </div>
                        </div>
                        <div className="appStatisticsElementSubtitle">31.9K reviews</div>
                    </div>
                    <div className="appStatisticsElement">
                        <div className="appStatisticsElementWrapper">5M+</div>
                        <div className="appStatisticsElementSubtitle">Downloads</div>
                    </div>
                    <div className="appStatisticsElement">
                        <div className="appStatisticsElementWrapper"><img src="https://play-lh.googleusercontent.com/IciOnDFecb5Xt50Q2jlcNC0LPI7LEGxNojroo-s3AozcyS-vDCwtq4fn7u3wZmRna8OewG9PBrWC-i7i=w48-h16" srcSet="https://play-lh.googleusercontent.com/IciOnDFecb5Xt50Q2jlcNC0LPI7LEGxNojroo-s3AozcyS-vDCwtq4fn7u3wZmRna8OewG9PBrWC-i7i=w96-h32 2x" style={{"backgroundSize":"contain","height":"16px","minWidth":"16px"}} alt="Content rating" /></div>
                        <div className="appStatisticsElementSubtitle"><span><span>Everyone</span></span>
                        {/* TODO: onclick, open google info modal */}
                            <div className="infoButton" role="button" tabIndex={0}><i className="google-material-icons font12">info</i></div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default AppStatistics;


